import React from 'react';
import { Link, graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import H1 from '@rotaready/frecl/build/H1';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Separator from '@rotaready/frecl/build/Separator';
import Button from '@rotaready/frecl/build/Button';
import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';

import { normalizePost } from '../utilities/blog';

import Body from '../components/layout/Body';
import SEO from '../components/SEO';
import ResponsiveContainer from '../components/layout/ResponsiveContainer';

import PostCardContainer from '../components/blog/PostCardContainer';
import Hero from '../components/blog/Hero';
import CategoryList from '../components/blog/CategoryList';

import { ViewportBreakpoints } from '../components/cssConstants';
import ContentWrapper from '../components/layout/ContentWrapper';

const isBrowser = typeof window !== 'undefined';

const Metadata = styled.div`
  margin: 20px 0;
`;

const Author = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
})`
  margin: 40px 0;
`;

const Avatar = styled.div`
  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
`;

const AuthorDetails = styled.div`
  text-align: left;
  margin-left: 10px;
`;

const AuthorName = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

const PaddedSeparator = styled(Separator)`
  margin: 100px 0;
`;

const CtaContainer = styled.div`
  text-align: center;
  margin-top: 60px;
`;

const CtaHeading = styled(H3)`
  margin-bottom: 20px;
`;

const SocialIcon = styled.button.attrs({
  type: 'button',
})`
  border: none;
  color: ${({ theme: { colors } }) => colors.brand};
  cursor: pointer;
  transition: all .15s ease-in-out;
  padding: 0;
  margin-right: 5px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.brand160};
  }
`;

const TwitterIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fab', 'twitter'],
  fixedWidth: true,
})``;

const FacebookIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fab', 'facebook-f'],
  fixedWidth: true,
})``;

const LinkedInIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fab', 'linkedin-in'],
  fixedWidth: true,
})``;

const ContentContainer = styled.div`
  margin: 50px auto 0 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 100px auto;
    max-width: 600px;
  }

  font-family: ${({ theme: { fonts } }) => fonts.Body1.fontFamily};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: ${({ theme: { colors } }) => colors.grey};

  a {
    color: ${({ theme: { colors } }) => colors.brand};
    text-decoration: none;

    &:hover {
      color: ${({ theme: { colors } }) => colors.brand160};
    }
  }

  h2, h3, h4, h5 {
    font-weight: 600;
    color: ${({ theme: { colors } }) => colors.brand160};
  }

  h2 {
    font-family: ${({ theme: { fonts } }) => fonts.H2.fontFamily};
    font-size: 38px;
    line-height: 54px;
    margin: 60px 0 20px 0;
  }

  h3 {
    font-family: ${({ theme: { fonts } }) => fonts.H3.fontFamily};
    font-size: 26px;
    line-height: 36px;
    margin: 40px 0 10px 0;
  }

  h4 {
    font-family: ${({ theme: { fonts } }) => fonts.H4.fontFamily};
    font-size: 20px;
    line-height: 28px;
    margin: 20px 0 10px 0;
  }

  h5 {
    font-family: ${({ theme: { fonts } }) => fonts.H5.fontFamily};
    font-size: 18px;
    line-height: 26px;
    margin: 20px 0 10px 0;
  }

  figure {
    margin: 60px auto;

    figcaption {
      color: ${({ theme: { colors } }) => colors.grey};
      font-size: 16px;
      margin-top: 20px;
      line-height: 22px;
    }

    &.overflow {
      text-align: center;
      max-width: unset;

      .gatsby-image-wrapper {
        overflow: visible !important;

        > div {
          padding-bottom: 100% !important;
        }
      }

      img {
        @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
          margin-left: -100px;
          margin-right: -100px;
          width: 800px !important;
        }

        @media (min-width: 1200px) {
          margin-left: -200px;
          margin-right: -200px;
          width: 1000px !important;
        }

        display: inline-table;

        // For browsers that support it, make the image stretch up to its maximum width, into the space
        // created by our negative margins.
        max-width: 100%;
        max-width: -webkit-fill-available;
        max-width: -moz-available;
        max-width: stretch;
      }
    }
  }

  ol, ul {
    margin: 40px;
    padding: 0;
  }

  ol {
    list-style: none;
    counter-reset: article-ol-counter;

    > li {
      counter-increment: article-ol-counter;
      text-indent: -35px;
      margin-left: 20px;
    }

    > li::before {
      content: counter(article-ol-counter);
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: #0d51ff;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      margin-right: 15px;
      text-indent: 0;
      display: inline-block;
      vertical-align: text-top;
      line-height: 20px;
    }
  }

  li {
    margin-bottom: 10px;
  }
`;

const PostContent = styled.div`
  margin: 60px 0;
`;

const openPopup = (url) => () => {
  const strWindowFeatures = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';

  if (isBrowser) {
    window.open(url, '_blank', strWindowFeatures);
  }
};

const BlogPostTemplate = ({ data }) => {
  const {
    post: rawPost,
    prevPost,
    nextPost,
  } = data;

  const post = normalizePost(rawPost);

  const previewPosts = [];

  if (prevPost) {
    previewPosts.push(normalizePost(prevPost));
  }

  if (nextPost) {
    previewPosts.push(normalizePost(nextPost));
  }

  const {
    title,
    excerpt,
    content,
    slug,
    date,
    author: {
      node: {
        name: authorName,
        avatar: {
          url: rawAvatarUrl,
        },
        description: authorDescription,
      },
    },
    categories,
    rotaready: {
      readingTime,
      metaDescription,
      featureImage,
    },
  } = post;

  const pageUrl = `https://rotaready.com/blog/${slug}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${pageUrl}&via=rotaready&text=${title}`;
  const facebookUrl = `http://www.facebook.com/sharer/sharer.php?u=${pageUrl}&t=${title}`;
  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${title}&summary=${excerpt}&source=rotaready.com`;

  // Wordpress spits out the avatar URL in http for some reason
  const avatarUrl = rawAvatarUrl.replace(/^http:\/\//i, 'https://');

  return (
    <Body>
      <SEO
        title={title}
        description={metaDescription}
        isBlogPost
        postData={{
          image: featureImage
            ? getSrc(featureImage.localFile) : '',
          authorName,
          slug: `blog/${slug}`,
          datePublished: date,
        }}
      />

      <ResponsiveContainer>
        <ContentWrapper>
          <Hero>
            <CategoryList categories={categories.nodes} />

            <H1 uistyle="brand160" text={title} />

            <Metadata>
              <Text uistyle="grey" text={`${date} - ${readingTime} min read`} size="sm" />
            </Metadata>

            <Author>
              <Avatar>
                <img src={avatarUrl} alt={authorName} />
              </Avatar>
              <AuthorDetails>
                <AuthorName uistyle="primary" text={authorName} />
                <Text uistyle="grey" text={authorDescription} />
              </AuthorDetails>
            </Author>
          </Hero>

          <ContentContainer>
            <PostContent dangerouslySetInnerHTML={{ __html: content }} />

            <RowV2 flexWrap>
              <ColumnV2>
                <H4 uistyle="brand160" text="Share this post" />

                <div>
                  <SocialIcon onClick={openPopup(twitterUrl)}><TwitterIcon /></SocialIcon>
                  <SocialIcon onClick={openPopup(facebookUrl)}><FacebookIcon /></SocialIcon>
                  <SocialIcon onClick={openPopup(linkedInUrl)}><LinkedInIcon /></SocialIcon>
                </div>
              </ColumnV2>

              <ColumnV2>
                {/* <H4 uistyle="brand160" text="Love this post" /> */}
                {/* Heart here */}
              </ColumnV2>
            </RowV2>
          </ContentContainer>

          <PaddedSeparator light />

          <PostCardContainer posts={previewPosts} />

          <CtaContainer>
            <CtaHeading uistyle="brand160" text="Like what you've read? Check us out!" />
            <Link to="/"><Button uistyle="primary" text="Visit Rotaready" /></Link>
          </CtaContainer>
        </ContentWrapper>
      </ResponsiveContainer>
    </Body>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
};

export default BlogPostTemplate;

export const query = graphql`
  query($pageId: String!, $previousPageId: String, $nextPageId: String) {
    post: wpPost(id: { eq: $pageId }) {
      ...CompletePostFragment
    }

    prevPost: wpPost(id: { eq: $previousPageId }) {
      ...PostSummaryFragment
    }

    nextPost: wpPost(id: { eq: $nextPageId }) {
      ...PostSummaryFragment
    }
  }
`;
